<template>
    <div>
        <h2>Home</h2>
        <div>
            <router-link v-if="isAuthenticated" to="/ideas">Go to Ideas</router-link>
            <router-link v-if="!isAuthenticated" to="/login">Log in</router-link>
        </div>
    </div>
    
</template>

<script>
import { IS_USER_AUTHENTICATED_GETTER } from '../store/storeconstants';
import {mapGetters} from 'vuex';

export default {
    name: 'Home',
    computed: {
        ...mapGetters('auth', {
            isAuthenticated: IS_USER_AUTHENTICATED_GETTER
        })
    }
}
</script>

<style scoped>
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'

import PrimeVue from 'primevue/config'

import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
//import 'primevue/resources/themes/lara-light-blue/theme.css';
//import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '/node_modules/primeflex/primeflex.css'
//import Axios from 'axios'
//import './assets/css/main.css'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(PrimeVue, {ripple: true})
app.mount('#app')

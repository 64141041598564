// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDz_EadTo_6S7rxO1As2F4iEgiPHam_LnM',
  authDomain: 'sucess-path.firebaseapp.com',
  databaseURL: 'https://sucess-path-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'sucess-path',
  storageBucket: 'sucess-path.appspot.com',
  messagingSenderId: '558994030153',
  appId: '1:558994030153:web:29b5654a3bf5269f8cc998',
  measurementId: 'G-0KHR7MXMK6'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

const db = getFirestore(app)

export {
    db, auth
}
<template>
    <div>
        <form @submit.prevent="onSignup()" autocomplete="on">
            <h2 class="pb-2">Sign Up</h2>
            <InlineMessage severity="error" v-if="error">{{ error }}</InlineMessage>
            <div class="flex flex-column gap-2">
                <label for="email">Email-Address</label>
                <InputText id="email" v-model.trim="email" type="email" :class="{ 'p-invalid': errors.email }"
                    aria-describedby="email-error" autocomplete="email" />
                <small class="p-error" id="email-error">{{ errors.email || '&nbsp;' }}</small>
            </div>
            <div class="flex flex-column gap-2">
                <label for="password">Passwort</label>
                <PasswordField id="password" promptLabel="Stärke" weakLabel="schwach"
                    mediumLabel="okay" strongLabel="stark" v-model.trim="password" type="password"
                    :class="{ 'p-invalid': errors.password }" aria-describedby="password" autocomplete="new-password"/>
                <small class="p-error" id="password-error">{{ errors.password || '&nbsp;' }}</small>
            </div>
            <Button type="submit" label="Sign Up" />
        </form>
        <p class="forgot-password text-right">
            Already registered
            <router-link to="/login">Login?</router-link>
        </p>
    </div>
</template>
 
<script>
import InputText from 'primevue/inputtext';
import PasswordField from 'primevue/password';
import InlineMessage from 'primevue/inlinemessage';
import Button from 'primevue/button';
import { mapActions } from 'vuex'
import { mapMutations } from 'vuex'
import SignupValidations from '../services/SignUpValidations'
import { LOADING_SPINNER_SHOW_MUTATION, SIGNUP_ACTION } from '../store/storeconstants';

export default {
    name: 'SignUp',
    components: {
        InputText, PasswordField, InlineMessage, Button,
    },
    data() {
        return {
            email: '',
            password: '',
            errors: [],
            error: '',
        }
    },
    methods: {

        ...mapActions('auth', { // 'auth' ist der namespace from store/modules/auth
            signup: SIGNUP_ACTION,
        }),
        ...mapMutations({
            showLoading: LOADING_SPINNER_SHOW_MUTATION,
        }),
        async onSignup() {
            // validate signup
            // if (validations ok) { do the signup } else show errors
            let validations = new SignupValidations(this.email, this.password);

            this.errors = validations.checkValidations();
            if ('email' in this.errors || 'password' in this.errors) {
                return false;
            }
            this.error = ''
            // show Spinner
            this.showLoading(true)
            //singup registration
            await this.signup({ email: this.email, password: this.password }).catch(error => {
                this.error = error
            })
            // hide spinner
            this.showLoading(false)
        },
    },
}
</script>
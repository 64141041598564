import { createRouter, createWebHistory } from 'vue-router'
import NotFound from '../views/NotFound'
import Home from '../views/Home'
import Tools from '../views/Tools'
import About from '../views/About'
import SignUp from '../views/SignUp'
import Login from '../views/Login'
import ForgotPassword from '../views/ForgotPassword'
import Ideas from '../views/Ideas'
import ViewIdea from '../views/ViewIdea'
import store from '../store/store'
import { IS_USER_AUTHENTICATED_GETTER } from '@/store/storeconstants'

const routes = [
    { path: '/', name: 'Home', component: Home, },
    { path: '/tools', name: 'Tools', component: Tools, },
    { path: '/about', name: 'About', component: About, },
    { path: '/signup', name: 'SignUp', component: SignUp, meta: { auth: false }, },
    //meta: ist für den router.beforeEach um links für z.b. nicht eingeloggte Benutzer zu sperren
    { path: '/login', name: 'Login', component: Login, meta: { auth: false }, },
    { path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword, },
    { path: '/ideas', name: 'Ideas', component: Ideas, meta: { auth: true }, },
    { path: '/ideas/:idea_id', name: 'view-idea', component: ViewIdea, meta: { auth: true }, },
    { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound, },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if ('auth' in to.meta && to.meta.auth && !store.getters[`auth/${IS_USER_AUTHENTICATED_GETTER}`]) {
        // auth ist true und nicht eingeloggt -> zu login umleiten
        next('/login')
    } else if ('auth' in to.meta && !to.meta.auth && store.getters[`auth/${IS_USER_AUTHENTICATED_GETTER}`]) {
        // auth ist false und eingeloggt -> zu ideas umleiten
        next('/ideas')
    } else {
        next();
    }
})

export default router

<template>
    <div>
        <header>
            <div class="stretch">
                <Navigation :title="title" />
            </div>
            <div class="right">
            </div>
        </header>
        <hr />
    </div>
</template>

<script>

import Navigation from './Navigation'

export default {
    name: 'Header',
    props: {
        title: String,
        showAddTask: Boolean,
    },
    components: {
        Navigation
    },

    methods: {
    },

    computed: {
    }
}
</script>

<style scoped>
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stretch {
    flex: 1;
    /* This will make the element stretch to fill the remaining space */
}

.right {
    /* You can use any alignment technique like margin or position to align right */
    margin-left: auto;
}
</style>

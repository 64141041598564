<template>
    <button @click="onClick()" :style="{background: backCol, color: foreCol}" class="btn">{{text}}</button>
</template>

<script>
    export default {
        name: 'Button',
        props: {
            text: String,
            backCol: String,
            foreCol: String,
        },

        methods: {
            onClick(){
                this.$emit('btn-click')
            },
        },
    }
</script>
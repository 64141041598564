<template>
    <div id="view-idea">
        <h3>Edit Idea</h3>
    </div>
</template>

<script>
export default {
    name: 'view-idea',
    data() {
        return {

        }
    }
}
</script>
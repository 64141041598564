export default class Validations {
    static isValidEmail(email) {
        // Regular expression pattern for basic email validation
        const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return pattern.test(email);
      }

      static hasMinimumLength(password, minLength) {
        return password.length >= minLength;
      }
      
      static hasLowercase(password) {
        return /[a-z]/.test(password);
      }
      
      static hasUppercase(password) {
        return /[A-Z]/.test(password);
      }
      
      static hasDigit(password) {
        return /[0-9]/.test(password);
      }
      
      static hasSpecialChar(password) {
        return /[!@#$%^&*]/.test(password);
      }      
}
<template>
    <div @dblclick="$emit('toggle-reminder', task.id)" :class="[task.active ? 'reminder': '', 'task']">
        <h3>{{ task.name }}
            <i @click="onDelete(task.id)" class="fas fa-times fa-fw"></i>
        </h3>
        <p style="text-align:left;">{{ task.priority }}           
            <span style="float:right;">
              <i>{{ task.upCount }}</i><i v-show="upvoted">(+1)</i>
              <i @click="onUpvote(task.id)" :class="[upvoted ? 'upvoted fa-sl ' : 'fa-sm ', 'fa fa-chevron-up fa-fw']"></i>
            </span>             
        </p> 
    </div>

</template>

<script>
    export default {
        name: 'Task',
        props: {
            task: Object,
        },

        methods: {
            onDelete (id){
               this.$emit('delete-task',id)
            },
            onUpvote (id){
              if(!this.upvoted)
              {
                //this.task.upCount++;
                this.$emit('upvote-task', id)
              }
              this.upvoted = true
            },
        },

      data() {
        return {      
          upvoted: false
        }
      },
    }
</script>

<style scope>
.fas {
  color: red;
}

.upvoted {
  color: rgb(13, 193, 13);
  font-weight: bold;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>
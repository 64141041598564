import Validations from "./Validations";

export default class SignupValidations {
    constructor(email, password) {
        this.email = email;
        this.password = password;
    }

    checkValidations() {
        let errors = [];
        //email validation
        if (!Validations.isValidEmail(this.email)) {
            errors['email'] = "Invalid Email";
        }
        //password validations
        if (!Validations.hasMinimumLength(this.password, 8)) {
            errors['password'] = "password length must be minimum 8 chars";
        }
        else if (!Validations.hasLowercase(this.password)) {
            errors['password'] = "password must contain lower case";
        }
        else if (!Validations.hasUppercase(this.password)) {
            errors['password'] = "password must contain upper case";
        }
        else if (!Validations.hasDigit(this.password) && !Validations.hasSpecialChar(this.pasword)) {
            errors['password'] = "password must have digits or special characters";
        }
        return errors;
    }


    checkUsername(username) {
        if (!Validations.hasMinimumLength(username, 3)) {
            return "Username must have minimum length of 3"
        }
        return "";
    }

    static getErrorMessageFromCode(errorCode) {
        switch (errorCode) {
            case 'auth/email-already-in-use':
                return 'Email already exists'
            case 'auth/invalid-email':
                return 'Invalid email'
            case 'auth/operation-not-allowed':
                return 'Operation not allowed'
            case 'auth/weak-password':
                return 'Weak password'
            case 'EMAIL_NOT_FOUND':
                return 'We do not have an account for this address'
            case 'auth/wrong-password':
                return 'Your password is wrong'
            case 'USER_DISABLED':
                return 'Your Account is disabled'
            case 'auth/user-not-found':
                return "User not found"
            default:
                return errorCode
        }
    }
}
<template>
    <div>
        <div class="top">
            <h2>Ideen</h2>
            <ToggleButton v-model="showAddIdea" onLabel="" offLabel="" onIcon="pi pi-times" offIcon="pi pi-plus" />
        </div>
        <InlineMessage severity="error" v-if="error">{{ this.error }}</InlineMessage>
        <AddIdea v-show="showAddIdea" @add-idea="addNewIdea"></AddIdea>
        <DataTable :value="ideas" tableStyle="min-width: 10rem">
            <Column header="">
                <template #body="slotProps">
                    <i :class="slotProps.data.kategory.icon"></i>
                    <!--
                        <i class="pi pi-building"></i>
                         <i :class="slotProps.kategory.icon"></i>
                        <img :src="${slotProps.data.image}"
                        :alt="slotProps.data.image" class="w-6rem shadow-2 border-round" />-->
                </template>
            </Column>
            <!--<Column field="id" header="Id"></Column>-->
            <Column field="title" header="Title"></Column>
            <Column field="description" header="Description"></Column>
        </DataTable>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import ToggleButton from 'primevue/togglebutton';
import AddIdea from '../components/AddIdea.vue'
import Column from 'primevue/column';
import InlineMessage from 'primevue/inlinemessage';
import axiosInstance from '../services/AxiosTokenInstance' //um automatisch in die URL die auth-Credentials anzuhängen
import { mapGetters, mapMutations } from 'vuex'
import { GET_USER_TOKEN_GETTER, GET_USER_ID_GETTER, LOADING_SPINNER_SHOW_MUTATION } from '../store/storeconstants'


import { db } from '@/firebase'
import { collection, getDocs, addDoc, onSnapshot } from 'firebase/firestore'

export default {
    data() {
        return {
            ideas: [],
            showAddIdea: false,
            error: '',
        }
    },
    components: {
        DataTable, Column, InlineMessage, AddIdea, ToggleButton
    },
    computed: {
        ...mapGetters('auth', {
            token: GET_USER_TOKEN_GETTER,
            userId: GET_USER_ID_GETTER,
        }),
    },
    mounted() {
        this.showLoading(true)
        this.error = ""

        /* const usersCol = collection(db, `users/${this.userId}/ideas`)
         await getDocs(usersCol).then(usersSnapshot => {
             usersSnapshot.forEach((doc) => {
                 this.ideas.push({ ...doc.data(), id: doc.id })
             })
             this.showLoading(false)
         }).catch(err => {
             this.error = err
             console.log(err)
             this.showLoading(false)
         })*/

         // für echtzeit-listener-Daten
        try {
            const unsubscribe = onSnapshot(collection(db, `users/${this.userId}/ideas`), (querySnapshot) => {
                const newIdeas = []
                //const cities = [];
                querySnapshot.forEach((doc) => {
                    newIdeas.push({ ...doc.data(), id: doc.id })
                })
                this.ideas = newIdeas
                this.showLoading(false)
            })
        }
        catch (err) {
            this.error = err
            console.log(err)
            this.showLoading(false)
        }



        /*await axiosInstance.get(`/ideas.json`)
            .then(response => {
                this.formatIdeas(response.data)                
                this.showLoading(false)
            })
            .catch(err => {
                if (err.response.status === 401) {
                    this.error = err.response.data.error
                } else {
                    this.error = err.response.data
                }
                this.showLoading(false)
            })*/

    },
    methods: {
        ...mapMutations({
            showLoading: LOADING_SPINNER_SHOW_MUTATION
        }),
        /*formatIdeas(ideas) {
            for (let key in ideas) {
                this.ideas.push({ ...ideas[key.data], id: key.id })
            }
            console.log(this.ideas)
        },*/
        async addNewIdea(idea) {
            let newIdea = structuredClone(idea)
            const usersCol = collection(db, `users/${this.userId}/ideas`)
            await addDoc(usersCol, newIdea)
                .then(response => {
                    //console.log(response.data)
                    newIdea.id = response.id;
                    this.showLoading(false)
                    this.ideas = [...this.ideas, newIdea]
                    this.showAddIdea = false
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        this.error = err.response.data.error
                    } else {
                        this.error = err.response.data
                    }
                    this.showLoading(false)
                })



            /* await axiosInstance.post(`/ideas.json`, newIdea)
                 .then(response => {
                     //console.log(response.data)
                     newIdea.id = response.data.name;
                     this.showLoading(false)
                     this.ideas = [...this.ideas, newIdea]
                     this.showAddIdea = false
                 })
                 .catch(err => {
                     if (err.response.status === 401) {
                         this.error = err.response.data.error
                     } else {
                         this.error = err.response.data
                     }
                     this.showLoading(false)
                 })*/


        },
    },
};
</script>

<style scoped>
.top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
}
</style>
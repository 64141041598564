<template> 
<div>
    <h1>404 - nicht gefunden :(</h1>
</div>

</template>
<script>
export default {

}
</script>

<style scoped>
h1 {
    padding: 10px 0px 10px 0px;
    text-align: center;
}
</style>
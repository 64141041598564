<template>
 <form @submit="onSubmit" class="add-form">
    <div class="form-control">
      <label>Tool</label>
      <input type="text" v-model="name" name="name" placeholder="Add Tool" />
    </div>
    <div class="form-control">
      <label>Priorität</label>
      <input
        type="integer"
        v-model="priority"
        name="priority"
        placeholder="Add priority"
      />
    </div>
    <div class="form-control form-control-check">
      <label>Set Active</label>
      <input type="checkbox" v-model="active" name="active" />
    </div>

    <input type="submit" value="Save Tool" class="btn btn-block" />
  </form>
</template>

<script>
    export default {
        name: 'AddTask',
        data() {
            return {
                name: '',
                priority: '',
                active: false,
            }
        },
        methods: {
            onSubmit(e) {
                e.preventDefault()

                if(!this.name) {
                    alert('Please add a Tool')
                    return
                }

                const newTask = {
                    //id: Math.floor(Math.random()*100000), -> wird vom JSON-Server automatisch hinzugefügt
                    name: this.name,
                    priority: this.priority,
                    active: this.active

                }

                this.$emit('add-task',newTask)

                this.name =''
                this.priority=''
                this.active = false

            }
        },
    }
</script>

<style scope>
.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}
</style>
<template>
  <div>
    <form @submit.prevent="onAddIdea()" autocomplete="off">
      <h2 class="pb-2">Neue Idee</h2>
      <InlineMessage severity="error" v-if="error">{{ error }}</InlineMessage>
      <SelectButton class="mb-2" v-model="idea.kategory" :options="options"  aria-labelledby="custom">
        <template #option="slotProps">
          <i :class="slotProps.option.icon"></i>
          <div> &nbsp; {{ slotProps.option.name }} </div>
        </template>
      </SelectButton>
      <div class="flex flex-column gap-2 mb-3">
        <label for="title">Title</label>
        <InputText id="title" v-model.trim="idea.title" type="text" />
      </div>
      <div class="flex flex-column gap-2 mb-3">
        <label for="desription">Idee</label>
        <InputText id="description" v-model.trim="idea.description" type="text" />
      </div>
      <Button type="submit" label="Speichern" />
    </form>
    <p class="text-right mt-2 mb-4">
    </p>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import InlineMessage from 'primevue/inlinemessage';
import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';


export default {
  name: 'AddIdea',
  data() {
    return {
      idea: {
        creationTime: '',
        kategory: '',
        title: '',
        description: '',
      },
      options: [
        {
          name: "Geschäft",
          icon: 'pi pi-briefcase',
          guid: "business",
          index: 1,
        },
        {
          name: "Ausflug",
          icon: 'pi pi-car',
          guid: "exp",
          index: 2,
        },
        {
          name: "Haus",
          icon: 'pi pi-building',
          guid: "home",
          index: 3,
        },
        {
          name: "Gelernt",
          icon: 'pi pi-heart',
          guid: "learnd",
          index: 4,
        },
        {
          name: "Interessant",
          icon: 'pi pi-info-circle',
          guid: "interesting",
          index: 4,
        }
      ],
      active: false,
      error: '',
    }
  },
  components: {
    InputText, InlineMessage, Button, SelectButton
  },
  methods: {
    onAddIdea(e) {
      //e.preventDefault()

      if (!this.idea.title) {
        this.error = 'title eingeben'
        return
      }
      this.idea.creationTime= Date.now();
      this.$emit('add-idea', this.idea)

      this.idea.description = ''
      this.idea.title = ''
      this.error = ''

    }
  },
}
</script>

<style scope></style>
<template>
  <div class="container">
    <Header title="Ideenspeicher" />
    <div class="pageContent">
      <the-loader v-if="showLoading"></the-loader>
      <router-view></router-view>
    </div>

    <Footer class="footer" />
  </div>
</template>

<script>
import Header from './components/Header'

import TheLoader from './components/TheLoader.vue'

import Footer from './components/Footer'


import { mapState, useStore } from 'vuex'
import { onBeforeMount } from 'vue'
import { AUTO_LOGIN_ACTION, IS_USER_AUTHENTICATED_GETTER } from './store/storeconstants'


export default {
  name: 'App',
  computed: {
    ...mapState({
      showLoading: state => state.showLoading,
      autoLogout: state => state.auth.session.autoLogout
    })
  },

  watch: {
    autoLogout(currValue, oldValue) {
      if (currValue && currValue != oldValue) {
        this.$router.replace('/login')
      }
    }
  },
  components: {
    Header,
    TheLoader,
    Footer,
  },
  setup() {
      const store = useStore()
      onBeforeMount(()=>{
        store.dispatch(`auth/${AUTO_LOGIN_ACTION}`)
      })
  },
  created() {
    //funktion aufrufen
   // this.$store.dispatch(`auth/${AUTO_LOGIN_ACTION}`)

  },
  methods: {
    
  },


  data() {
    return {
    }
  },

}
</script>

<style>
/*<style  src="../src/assets/css/main.css">*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

/*
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.container {
  overflow: auto;
  min-height: 300px;
  /*border: 1px solid steelblue;*/

  border-radius: 5px;
  background-color: white;
}

.pageContent {
  padding: 30px;
  max-width: 700px;
  margin: 30px auto;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}
</style>

import SignupValidations from "@/services/SignUpValidations";
import { AUTH_ACTION, AUTO_LOGIN_ACTION, AUTO_LOGOUT_ACTION, LOGIN_ACTION, LOGOUT_ACTION, REFRESH_AUTH_TOKEN, SET_AUTO_LOGOUT_MUTATION, CLEAR_USER_DATA_MUTATION, SET_USER_DATA_MUTATION, SIGNUP_ACTION } from "@/store/storeconstants";
import axios from "axios";
import {auth } from '@/firebase'
import {
    createUserWithEmailAndPassword,
    signOut,
    signInWithEmailAndPassword,
} from 'firebase/auth'
import router from '@/router'

let timer = '';
export default {
   async [LOGOUT_ACTION](context) {
        /*context.commit(SET_USER_DATA_MUTATION, {
            email: null,
            session: {
                userId: null,
                token: null,
                expiresIn: null,
                refreshToken: null,
            }
        });
        localStorage.removeItem('session');
        if (timer) {
            clearTimeout(timer);
        }*/
        await signOut(auth)
        context.commit(CLEAR_USER_DATA_MUTATION)

        if (timer) {
            clearTimeout(timer);
        }

        //his.$router.push('/login');
    },

   /* [AUTO_LOGOUT_ACTION](context) {
        context.dispatch(LOGOUT_ACTION);
        context.commit(SET_AUTO_LOGOUT_MUTATION);
    },*/

    [AUTO_LOGIN_ACTION](context) {
        auth.onAuthStateChanged(async user => {
            if (user === null) {
                context.commit(CLEAR_USER_DATA_MUTATION)
                context.commit(SET_AUTO_LOGOUT_MUTATION)

            } else {
                context.commit(SET_USER_DATA_MUTATION, user)
                /*if(this.$router.isReady() && this.$router.currentRoute.value.path === '/login') {
                    this.$router.push('/ideas')
                }*/
            }

            if (router.isReady()) {
                let curr = router.currentRoute.value
                if ('auth' in curr.meta && curr.meta.auth && user ===null) {
                  // auth ist true und nicht eingeloggt -> zu login umleiten
                  router.push('/login')
                } else if ('auth' in curr.meta && !curr.meta.auth && user!=null) {
                  // auth ist false und eingeloggt -> zu ideas umleiten
                  router.push('/ideas')
                }
              }
        })
        // prüft, ob im lokalen Speicher die User-Daten vorhanden sind.
        /*let sessionDataString = localStorage.getItem('session');
        if (sessionDataString) {
            let sessionData = JSON.parse(sessionDataString);
            let expirationTime =  (sessionData.lastRefresh + sessionData.expiresIn*1000) -new Date().getTime() -30000;
            // user lädt neu: ist das session-Token noch mindestens 30 sekunden gültig?
            console.log("AutoLogAction: ", expirationTime, sessionData)
            if (expirationTime < 0) {
                // session-Token keine 30 Sekunden mehr gültig -> zu alt
                // auto-logout durchführen
                context.dispatch(AUTO_LOGOUT_ACTION);
            } else {
                // session-Token noch ausreichend gültig -> 30 Sekunden vor Ablauf erneuern
                timer = setTimeout(() => {
                    context.dispatch(REFRESH_AUTH_TOKEN, sessionData)
            }, expirationTime)
            }

            context.commit(SET_USER_TOKEN_DATA_MUTATION, sessionData)
        }*/
    },

   /* async [AUTH_ACTION](context, payload) {
        let postData = {
            email: payload.email,
            password: payload.password,
            returnSecureToken: true,
        };

        let response = ''

        // context.commit(LOADING_SPINNER_SHOW_MUTATION, true, {
        //     root: true, // weil sich die mutation der spinner in store.js im root befindet
        // });

        try {
            response = await axios.post(payload.url, postData);

        } catch (err) {
            let errorMsg = SignupValidations.getErrorMessageFromCode(err.response.data.error.errors[0].message)
            // context.commit(LOADING_SPINNER_SHOW_MUTATION, false, {
            //     root: true, // weil sich die mutation der spinner in store.js im root befindet
            // });
            throw errorMsg
        }
        //context.commit(LOADING_SPINNER_SHOW_MUTATION, false, {
        //    root: true, // weil sich die mutation der spinner in store.js im root befindet
        //});
        console.log(auth.currentUser)
        /*if (response.status === 200) {
            let tokenData = {
                email: response.data.email,
                session: {
                    userId: response.data.localId,
                    token: response.data.idToken,
                    expiresIn: response.data.expiresIn,
                    lastRefresh: new Date().getTime(),
                    refreshToken: response.data.refreshToken,
                }
            }

            let expirationTime = (tokenData.session.expiresIn-30) * 1000 //30sekunden vor expiration erneut aufrufen -> to millisecons
            timer = setTimeout(() => {
                //context.dispatch(AUTO_LOGOUT_ACTION)
                context.dispatch(REFRESH_AUTH_TOKEN, tokenData.session)
            }, expirationTime)




            localStorage.setItem('session', JSON.stringify(tokenData.session))
            context.commit(SET_USER_DATA_MUTATION, tokenData)
        }
        //console.log(response);
    },*/

    async [LOGIN_ACTION](context, payload) {
        /*return context.dispatch(AUTH_ACTION, {
            ...payload,
            url: `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyDz_EadTo_6S7rxO1As2F4iEgiPHam_LnM`,
        });*/
        try {
            await signInWithEmailAndPassword(auth, payload.email, payload.password)
            //response = await axios.post(payload.url, postData);

        } catch (err) {
            let errorMsg = SignupValidations.getErrorMessageFromCode(err.code)//err.response.data.error.errors[0].message)
            // context.commit(LOADING_SPINNER_SHOW_MUTATION, false, {
            //     root: true, // weil sich die mutation der spinner in store.js im root befindet
            // });
            throw errorMsg
        }
        //context.commit(LOADING_SPINNER_SHOW_MUTATION, false, {
        //    root: true, // weil sich die mutation der spinner in store.js im root befindet
        //});
        context.commit(SET_USER_DATA_MUTATION, auth.currentUser)
        console.log(auth.currentUser)
    },


    async [SIGNUP_ACTION](context, payload) {

       /* return context.dispatch(AUTH_ACTION, {
            ...payload,
            url: `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyDz_EadTo_6S7rxO1As2F4iEgiPHam_LnM`, //der payload die Url hinzufügen
        });*/
        try {
            await createUserWithEmailAndPassword(auth, payload.email, payload.password)
            //response = await axios.post(payload.url, postData);

        } catch (err) {
            let errorMsg = SignupValidations.getErrorMessageFromCode(err.code)//err.response.data.error.errors[0].message)
            // context.commit(LOADING_SPINNER_SHOW_MUTATION, false, {
            //     root: true, // weil sich die mutation der spinner in store.js im root befindet
            // });
            throw errorMsg
        }
        //context.commit(LOADING_SPINNER_SHOW_MUTATION, false, {
        //    root: true, // weil sich die mutation der spinner in store.js im root befindet
        //});
        context.commit(SET_USER_DATA_MUTATION, auth.currentUser)
        console.log(auth.currentUser)
    },

   /* async [REFRESH_AUTH_TOKEN](context, payload) {
        let postData = {
            grant_type: "refresh_token",
            refreshToken: payload.refreshToken,
        };

        let response = ''

        // context.commit(LOADING_SPINNER_SHOW_MUTATION, true, {
        //     root: true, // weil sich die mutation der spinner in store.js im root befindet
        // });

        try {
            response = await axios.post(`https://securetoken.googleapis.com/v1/token?key=AIzaSyDz_EadTo_6S7rxO1As2F4iEgiPHam_LnM`, postData);

        } catch (err) {
            console.log(err.response.data.error.errors[0].message)
            context.dispatch(AUTO_LOGOUT_ACTION);
            // context.commit(LOADING_SPINNER_SHOW_MUTATION, false, {
            //     root: true, // weil sich die mutation der spinner in store.js im root befindet
            // });
        }
        //context.commit(LOADING_SPINNER_SHOW_MUTATION, false, {
        //    root: true, // weil sich die mutation der spinner in store.js im root befindet
        //});
        if (response.status === 200) {
              let tokenData = {
                userId: response.data.user_id,
                token: response.data.id_token,
                expiresIn: response.data.expires_in,
                lastRefresh: new Date().getTime(),
                refreshToken: response.data.refresh_token,
            }

            let expirationTime = (tokenData.expiresIn-30) * 1000 //30 sekunden vor expiration erneut aufrufen -> to millisecons
            timer = setTimeout(() => {
                //context.dispatch(AUTO_LOGOUT_ACTION)
                context.dispatch(REFRESH_AUTH_TOKEN, tokenData)
            }, expirationTime)


            localStorage.setItem('session', JSON.stringify(tokenData))
            context.commit(SET_USER_TOKEN_DATA_MUTATION, tokenData)
        }
        console.log("refresh Data: ", response);
    },*/
};
<template>
    <div>
        <div class="top">
            <h1>Tools</h1>
            <Button @btn-click="toggleAddTask" :text="showAddTask ? 'Close' : 'Add Task'"
                :backCol="showAddTask ? 'red' : 'green'" />
        </div>
        <AddTask v-show="showAddTask" @add-task="addTask" />
        <Tasks @toggle-reminder="toggleReminder" @delete-task="deleteTask" @upvote-task="upvoteTask" :tasks="tasks" />
    </div>
</template>

<script>
import Button from '../components/Button'
import Tasks from '../components/Tasks'
import AddTask from '../components/AddTask'

export default {
    name: 'Tools',
    props: {
    },
    components: {
        Tasks,
        AddTask,
        Button,
    },
    methods: {
        toggleAddTask() {
            this.showAddTask = !this.showAddTask
        },
        async addTask(task) {
            const res = await fetch('https://sandbox.flewq.com/api/tools', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(task)
            })

            const data = await res.json()
            console.log(JSON.stringify(data));
            this.tasks = [...this.tasks, task]
        },
        async deleteTask(id) {
            if (confirm('Are you sure?')) {
                const res = await fetch(`https://sandbox.flewq.com/api/tools/${id}`, {
                    method: 'DELETE'
                })

                res.status === 200 ? (this.tasks = this.tasks.filter((task) => task.id !== id)) : alert('Error deleting task')
            }
        },
        async upvoteTask(id) {
            const res = await fetch(`https://sandbox.flewq.com/api/tools/${id}/upvote`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(id)
            })

            const data = await res.json()
            console.log(JSON.stringify(data));
            this.tasks = this.tasks.map((task) => task.id == id ? { ...task, upCount: task.upCount + 1 } : task)
        },
        async toggleReminder(id) {
            const taskToToggle = this.tasks.filter(task => task.id == id);
            //const taskToToggle = await this.fetchTask(id)
            const updTask = { ...taskToToggle, active: !taskToToggle[0].active }

            const res = await fetch(`https://sandbox.flewq.com/api/tools/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(updTask)

            })

            const data = await res.json()
            this.tasks = this.tasks.map((task) => task.id == id ? { ...task, active: !updTask[0].active } : task)
        },
        async fetchTasks() {
            //const res = await fetch('api/tasks')
            const res = await fetch('https://sandbox.flewq.com/api/tools')
            const data = await res.json()
            console.log(JSON.stringify(data))
            return data
        },
        async fetchTask(id) {
            const res = await fetch(`https://sandbox.flewq.com/api/tools/${id}`)

            const data = await res.json()

            return data
        },

    },
    data() {
        return {
            tasks: [],
            showAddTask: false,
        }
    },

    async created() {
        this.tasks = await this.fetchTasks()
    },
}
</script>


<style scoped>
.top {
    display: flex;
    justify-content: space-between;
}
</style>
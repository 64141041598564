import axios from 'axios'
import store from '../store/store';
import { GET_USER_TOKEN_GETTER } from '@/store/storeconstants';
const axiosInstance = axios.create({});

axiosInstance.defaults.baseURL = `https://sucess-path-default-rtdb.europe-west1.firebasedatabase.app`;

axiosInstance.interceptors.request.use((config) => {
    let params = new URLSearchParams();
    let token = store.getters[`auth/${GET_USER_TOKEN_GETTER}`] // Token aus store holen
    params.append('auth', token); // token an url-Aufruf anhängen
    config.params = params;
    return config;
});

export default axiosInstance
<template>
    <hr />
    <footer>
        <p>Copyright &copy; 2023</p>
        <router-link to="/about">About</router-link> |
        <router-link to="/signup">Sign up</router-link>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scope>
a {
    color: #333;
}

footer {
    margin: 20px;
    text-align: center;
}
</style>
<template>
    <div>
        <form @submit.prevent="onLogin()" autocomplete="on">
            <h2 class="pb-2">Login {{ firstName }}</h2>
            <InlineMessage severity="error" v-if="error">{{ error }}</InlineMessage>
            <div class="flex flex-column gap-2">
                <label for="email">Email-Address</label>
                <InputText id="email" v-model.trim="email" type="email" :class="{ 'p-invalid': errors.email }"
                    aria-describedby="email-error" autocomplete="email"/>
                <small class="p-error" id="email-error">{{ errors.email || '&nbsp;' }}</small>
            </div>
            <div class="flex flex-column gap-2">
                <label for="password">Passwort</label>
                <InputText id="password" v-model.trim="password" type="password" :class="{ 'p-invalid': errors.password }"
                    aria-describedby="password" autocomplete="current-password" />
                <small class="p-error" id="password-error">{{ errors.password || '&nbsp;' }}</small>
            </div>
            <Button type="submit" label="Login" />
        </form>
        <p class="text-right mt-2 mb-4">
            <router-link to="/forgot-password">Forgot password ?</router-link>
        </p>
    </div>
</template>
 
<script>
import InputText from 'primevue/inputtext';
import InlineMessage from 'primevue/inlinemessage';
import Button from 'primevue/button';
import { mapState, mapActions, mapMutations } from 'vuex'
import SignupValidations from '../services/SignUpValidations'
import { LOADING_SPINNER_SHOW_MUTATION, LOGIN_ACTION } from '../store/storeconstants';

export default {
    name: 'Login',
    components: {
        InputText, Button, InlineMessage,
    },
    computed: {
        ...mapState('auth', {
            firstName: (state) => state.name,
        }),
    },

    data() {
        return {
            email: '',
            password: '',
            errors: [],
            error: '',
        }
    },
    methods: {
        ...mapActions('auth', {
            login: LOGIN_ACTION,
        }),
        ...mapMutations({
            showLoading: LOADING_SPINNER_SHOW_MUTATION
        }),
        async onLogin() {
            // validate signup
            // if (validations ok) { do the signup } else show errors
            let validations = new SignupValidations(this.email, this.password);

            this.errors = validations.checkValidations();
            if ('email' in this.errors || 'password' in this.errors) {
                return false;
            }
            this.error = ''
            this.showLoading(true);
            //login check
            try {
                await this.login({ email: this.email, password: this.password })
            } catch (e) {
                this.error = e
                this.showLoading(false)
            }
            this.showLoading(false)
            this.$router.push('/ideas')
        },
    },
}
</script>
<template>
    <div class="cursor-pointer">
        <Avatar icon="pi pi-user" id="avatar" @click="overlayVisible = true" class="mr-2" size="medium"
            style="background-color: #2196f3; color: #ffffff" shape="circle" />
        <Sidebar v-model:visible="overlayVisible" position="right">
            <h2 class="pb-3">Account</h2>
            <div class="flex flex-column row-gap-2">
                <Button type="button" label="Logout" icon="pi pi-sign-out" @click.prevent="onLogout()" />
                <Button type="button" disabled label="Change password" icon="pi pi-key rotate-180" @click.prevent="onLogout()" />
                <Button type="button" disabled label="Delete Account" severity="danger" icon="pi pi-trash"
                    @click.prevent="onLogout()" />
            </div>
        </Sidebar>
    </div>
</template>

<script>
import Avatar from 'primevue/avatar'
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import { mapActions } from 'vuex';
import { LOGOUT_ACTION } from '../store/storeconstants';

export default {


    name: 'Profile',
    components: {
        Avatar, Sidebar, Button
    },
    props: {
    },


    methods: {
        ...mapActions('auth', {
            logout: LOGOUT_ACTION
        }),
        onLogout() {
            this.logout();
            this.$router.replace('/login');
            this.overlayVisible = false;
        },
    },
    data() {
        return {
            overlayVisible: false,
        }
    }
}
</script>

<style scoped></style>
<template>
    <Menubar :model="items">
        <template #start>
            <router-link to="/">
                <img alt="logo" src="/images/logo.ico" height="40" class="mr-2" />
            </router-link>
        </template>
        <template #end>
            <Profile v-show="isAuthenticated"/>
            <div class="btnGroup">
                <Button v-show="!isAuthenticated" @click.prevent="$router.push('/login')" label="Log in" />
                <Button v-show="!isAuthenticated" @click.prevent="$router.push('/signup')" label="Sign up"
                    severity="secondary" />
            </div>
        </template>
    </Menubar>
</template>

<script>
import Profile from './Profile'
import { mapGetters, mapActions } from 'vuex';
import Menubar from 'primevue/menubar'
import Button from 'primevue/button'
import { IS_USER_AUTHENTICATED_GETTER } from '../store/storeconstants';

export default {
    name: 'Navigation',
    components: {
        Menubar, Profile, Button
    },
    props: {
        title: {
            type: String,
            default: 'Hello World',
        },
    },
    computed: {
        ...mapGetters('auth', {
            isAuthenticated: IS_USER_AUTHENTICATED_GETTER
        })
    },
    methods: {

    },
    data() {
        return {
            items: [
                {
                    label: "Home",
                    icon: 'pi pi-fw pi-home',
                    to: "/",
                },
                {
                    label: "Tools",
                    icon: 'pi pi-fw pi-wrench',
                    to: "/tools",
                },
                {
                    label: "Ideen",
                    icon: 'pi pi-fw pi-list',
                    to: "/ideas",
                    visible: () => this.isAuthenticated,
                },
            ],
        }
    }
}
</script>

<style scoped>
.btnGroup {
    display: flex;
    justify-content: space-between;
    column-gap: 2px;
}
</style>
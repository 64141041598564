import { SET_AUTO_LOGOUT_MUTATION, CLEAR_USER_DATA_MUTATION, SET_USER_DATA_MUTATION, SET_USER_TOKEN_DATA_MUTATION } from "@/store/storeconstants";

export default {
    [SET_USER_DATA_MUTATION] (state,user) {
        state.user = user
        state.session.autoLogout = false
    },

    [CLEAR_USER_DATA_MUTATION] (state, user) {
        state.user = null
    },

    [SET_AUTO_LOGOUT_MUTATION] (state) {
        state.session.autoLogout = true
    },
};
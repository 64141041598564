import { GET_USER_TOKEN_GETTER, GET_USER_ID_GETTER, IS_USER_AUTHENTICATED_GETTER } from "@/store/storeconstants";

export default {
    [GET_USER_TOKEN_GETTER]: state => {
        return state.user.accessToken;
    },

    [GET_USER_ID_GETTER]: state => {
        return state.user.uid;
    },

    [IS_USER_AUTHENTICATED_GETTER] (state) {
        return !!state.user;
    }
};
<template>
<div>
    <h3>Version 1.0.0</h3>
        Impressum:
        Michael Hack
        Ebersbrunn 38, 96160 Geiselwind
</div>
</template>

<script>
    export default {
        name: 'About',
    }
</script>

<style scoped>
div {
    margin-top: 30px;
    text-align: left;
}
</style>
export const SIGNUP_ACTION = '[actions] sigunup user'
export const LOGIN_ACTION = '[actions] login user'
export const LOGOUT_ACTION = '[actions] logout user'
export const AUTH_ACTION = '[actions] do user auth'
export const AUTO_LOGIN_ACTION ='[actions] auto login'
export const AUTO_LOGOUT_ACTION ='[actions] auto logout'
export const REFRESH_AUTH_TOKEN ='[actions] refresh auth token'
//export const SET_USER_TOKEN_DATA_MUTATION = '[mutations] set user token'
export const SET_USER_DATA_MUTATION = '[mutations] set user data'
export const CLEAR_USER_DATA_MUTATION = '[mutations] clear user'
export const SET_AUTO_LOGOUT_MUTATION = '[mutations] set auto logout'
export const LOADING_SPINNER_SHOW_MUTATION = '[mutations] show loading spinner'

export const GET_USER_TOKEN_GETTER = '[getters] auth user token'
export const GET_USER_ID_GETTER = '[getters] auth user id'
export const IS_USER_AUTHENTICATED_GETTER = '[getters] is user authenticated'